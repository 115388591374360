import React from 'react';
import './kvakk.css';
import Threads from './Threads';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import ViewThread from './ViewThread';

function App() {
  return (
	<BrowserRouter>
		<div className="App">
			<h1><Link to="/">Andeby Online</Link></h1>
			<Routes>
				<Route path="/innlegg/:id/:page" element={<ViewThread />} />
				<Route path="/innlegg/:id" element={<ViewThread />} />
				<Route path='/' element={<Threads />} />
			</Routes>
		</div>
	</BrowserRouter>
  );
}

export default App;
