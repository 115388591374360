import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom';

function Threads() {

	const [threads, setThreads] = useState([] as Thread[]);
	const [page, setPage] = useState(1);

	const fetchThreads = async (pageNumber? : number) => {
		const res = await fetch('https://forum-api.kvakk.no/v1/threads/?page=' + (pageNumber ? pageNumber : page));
		const json = await res.json();
		setThreads([...threads].concat(json.data));
	}
	
	useEffect(() => {
		fetchThreads();
	}, []);

	const sidetall = (stop : number) =>
    Array.from(
    { length: stop },
    (value, index) => 1 + index
    );

	return (
	<div>
		<p className="forum-down">Det er for augeblikket ikkje mogleg å skrive nye innlegg i diskusjonsforumet. Dette vil kanskje bli mogleg igjen i framtida, men det føreligger ingen konkrete planar om nøyaktig når.</p>
		<p className="forum-down">Du kan bla i arkivet over eldre innlegg under.</p>

		{threads.map(el => <article className="thread" key={el.id}>
			<h2 className="title"><Link to={`/innlegg/${el.id}/`}>{el.title}</Link></h2>
			<div className="metadata">
				<div><h3>Oppretta:</h3><p>{el.created}</p></div>
				<div><h3>Sist oppdatert:</h3><p>{el.lastUpdated}</p></div>
				<div><h3>Antall innlegg:</h3><p>{el.posts}</p></div>
			</div>
			<nav className="pages">{el.posts > 20 ? sidetall(Math.ceil(el.posts / 20)).map(page => <Link to={`/innlegg/${el.id}/${page}/`} key={page}>Side {page}</Link>) : undefined}</nav>
		</article>)}
		<button className="show-more" onClick={() => {
			const newPage = page + 1;
			setPage(newPage);
			fetchThreads(newPage);
		}}>Vis fleire ...</button>
	</div>
  )
}

interface Thread {
	id: number,
	title: string,
	posts: number,
	created: string,
	lastUpdated: string,
}

export default Threads