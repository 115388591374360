import React, {useState, useEffect} from 'react';
import {NavLink, useParams} from 'react-router-dom';

function ViewThread() {
	const [posts, setPosts] = useState([] as Post[]);
	const [title, setTitle] = useState('');
	const [numberOfPosts, setNumberOfPosts] = useState(0);

	const {id, page} = useParams();
	const fetchPosts = async () => {
		const res = await fetch('https://forum-api.kvakk.no/v1/posts/' + id + '/' + (page ? '?page=' + page : ''));
		const json = await res.json();
		setPosts(json.data);
		setTitle(json.title);
		setNumberOfPosts(json.posts);
	}
	
	useEffect(() => {
		fetchPosts();
	}, [page]);

	const sidetall = (stop : number) =>
    Array.from(
    { length: stop },
    (value, index) => 1 + index
    );

	return (
	<div>
		<h2>{title}</h2>
		{posts.map((el, index) => <article className="post" key={index}>
			<p className="username">{el.username}</p>
			<div className="content" dangerouslySetInnerHTML={{__html: el.text}}></div>
			<p className="created">{el.created}</p>
		</article>)}
		<nav className="pages">{numberOfPosts > 20 ? sidetall(Math.ceil(numberOfPosts / 20)).map(page => <NavLink key={page} to={`/innlegg/${id}/${page}/`}>Side {page}</NavLink>) : undefined}</nav>
	</div>
  )

}

interface Post {
	text: string,
	created: string,
	username: string,
}

export default ViewThread;